////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgb(255, 255, 255);
}
.bg-secondaryColor {
  background-color: rgb(2, 83, 42);
}
.bg-thirdColor {
  background-color: rgb(0, 0, 0);
}
.bg-fourthColor {
  background-color: rgb(239, 172, 23);
}
.bg-fifthColor {
  background-color: rgba(239, 172, 23, 0.19);
}
.bg-sixthColor {
  background-color: rgb(160, 160, 167);
}
.bg-seventhColor {
  background-color: rgb(0, 0, 0);
}
